@import '../../index.css';

#cardBannerHolder {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
}

#cardBannerContainer {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  background-color: #F3F4F6;
  display: flex;
  align-items: center;
}

.swiper-container {
  height: 100%;
  width: 97%;
}

/* .swiper-pagination{
    top: 272px;
} */

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #111F43;
  opacity: 1;
}

span.swiper-pagination-bullet {
  background-color: #75B4DF;
  opacity: 1;
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
  margin-right: 1px;
}


/* .swiper-wrapper{
    margin-left: 50px;
}
 */



/*////////////*
  /* .sliderWrapper {
    :global(.swiper-pagination-bullet) {
      background: white;
      width: 1rem;
      height: 1rem;
      border-radius: .5rem;
      opacity: 1;
  }
  
  :global(.swiper-pagination-bullet-active) {
    background-color: blue;
    width: 5rem;
    height: 1rem;
    border-radius: .5rem;
  }
} */

@media only screen and (max-width: 700px) {
  #cardBannerHolder {
    height: 400px;
  }
}