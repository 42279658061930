#footerHolder{
    width: 100%;
    /* height: 100px; */
    display: flex;
    justify-content: center;
}

#footerCenter{
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;
}

#footerLinks{
    height: 60px;
    width: 100%;
    background-color: #111F43;
    max-width: 1920px;
    display: flex;
    align-items: center;
}

#footerBottom{
    height: 100px;
    max-width: 1920px;
    display: flex;
    align-items: center;
    margin-left: 30px;
}

#initialSpace{
    margin-left: 30px;
}

.dot{
    color: white;    
    margin: 0px 15px;
}

#footerIcon{
    display: flex;
    height: 40px;
    width: auto;
}

#crSpace {
    margin-left: 15px;
}

@media screen and (max-width : 1200px ) {
    #footerHolder{
        height: 200px;
    }

    #footerIcon{
        height: 30px;
    }
}

@media screen and (max-width : 700px ) {
    #footerLinks{
        padding-top: 15px;
        flex-direction: column;
        height: 160px;
    }

    #footerBottom{
        justify-content: center;
        margin-left: 0px;
    }

}

