@import '../../index.css';

#headerHolder{
    width: 100%;
    height: 440px;
    display: flex;
    justify-content: center;
    background-color: #fdfdfd;
}

#headerContainer{
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 1920px;
}

.headerImageStyle {
    width: 100%;
}

.headerContentDiv {
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#headerTitle{
    width: 350px;
    color: white;
    text-align: center;
}

#headerSub{
    width: 360px;
    margin-top: 10px;
    text-align: center;
    color: white;
    font-size: 20px;
}

#headerCTA{
    display: flex;
    cursor: pointer;
    width: 150px;
    background-color: #DB5443;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    color:white;
    margin-top: 20px;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    #headerHolder{
        height: 300px;
    }
}

@media screen and (max-width: 800px) {
    #headerHolder{
        height: 200px;
    }

    #headerTitle{
        font-size: 24px;
    }

    #headerSub{
        font-size: 16px;
    }

    #headerCTA{
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        width: 120px;
    }
}

@media screen and (max-width: 450px) {

    #headerHolder{
        height: 170px;
    }

    #headerTitle{
        font-size: 20px;
        width: 250px;
    }

    #headerSub{
        font-size: 12px;
        margin-top: 5px;
        width: 300px;
    }

    #headerCTA{
        margin-top: 10px;
    }
}