@import '../../index.css';

#speakerHolder {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
}

#speakerContainer {
    max-width: 1920px;
    display: flex;
    width: 100%;
    background-color: white;
}

#speakerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#speakerIcon {
    height: 70px;
    width: auto;
    margin-right: 20px;
}

#speakerText {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 350px;
}

#speakerTitle {
    display: flex;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 5px;
}

#speakerT1 {
    color: #112041;
    margin-right: 5px;
}

#speakerT2 {
    color: #75B4DF;
}

#speakerSub {
    color: #112041;
    font-size: 20px;
}


@media screen and (max-width : 1000px) {

    #speakerLeft {
        width: 100%;
        justify-content: flex-start;
        margin-left: 50px;
    }
}

@media screen and (max-width : 800px) {
    #speakerTitle{
        font-size: 20px;
    }

    #speakerSub{
        font-size: 16px;
    }
}

@media screen and (max-width : 500px){
    #speakerLeft{
        margin-left: 20px;
    }

    #speakerText{
    width: 100%;
    padding-right: 20px;
    }
}