@import url('https://use.typekit.net/xqj7jov.css');

* {
    margin: 0;
  }
  
  a{
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  body {
    background-color: var(--bg-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

/* @font-face {
  font-family: 'francisco_serial-regular';
  src: url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff2') format('woff2'),
       url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adamina-Regular';
  src: url('./fonts/Adamina/Adamina-Regular.ttf') format('ttf');
} */

/* .btnText {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.7;
  color: #FFFFFF;
} */

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

