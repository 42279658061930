@import '../../index.css';

.cardContainer{
    display: flex;
    width: 90%;
    height: 80%;
    margin-top: 20px;
    background-color: #111F43;
    border-radius: 6px 5px 5px 6px;
    overflow: hidden;
}

.speakerImgContainer {
    width: 50%;
    height: 100%;
    /* width: calc(50% - 10px);
    height: calc(100% - 20px); */
    /* margin: 10px; */
    margin-right: 0px;
    background-image: url();
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border-radius: 6px 5px 5px 6px; */
}

/* .speakerImg{
   width: 44%;
   height: auto;
   max-width: auto; 
   border-radius: 5px 0 0 5px;
} */


.cardRight{
    height: 100%;
    width: auto;
    flex-grow: 100;
    color: white;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardText{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    width: 90%;
    margin-top: 2px;
}

.cardButton{
    margin-top: 15px;
    height: 34px;
    width: 60%;
    border-style: solid;
    border-color: white;
    cursor: pointer;
    line-height: 34px;
    text-align: center;
    color: white;
    border-radius: 5px;
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "";
}

@media only screen and (max-width: 1024px) { 

    .cardText{
        font-size: 20px;
        width: 90%;
    }
    
    .cardButton{
        margin-top: 10px;
        width: 50%;
        border-width: 2px;
    }
}

@media only screen and (max-width: 700px) {
    #cardBannerHolder {
      height: 400px;
    }

    .cardContainer{
        flex-direction: column;
        height: 83%;
    }

    .speakerImgContainer {
        width: 100%;
        height: 60%;
    }

    .cardRight{
        height: 50%;
    }
  }

@media only screen and (max-width: 600px) { 
    .cardText{
        font-size: 20px;
        width: 90%;
    }
    
    .cardButton{
        margin-top: 10px;
        width: 50%;
        border-width: 2px;
    }
}