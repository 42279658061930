.modalOverlayContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.modalContainer {
    position: relative;
    background-color: white;
    height: auto;
    width: 50%;
    min-width: 500px;
    min-height: 400px;
}

.closeModal {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0px !important;
    cursor: pointer;
    height: 35px;
}

.modalDoctorImg {
    float: left;
    max-width: 200px;
    margin: 0px 20px 20px 0px;
}

.modalContainer>h1 {
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.modalContainer>h2 {
    margin-bottom: 5px;
}

.modalContainer>p {
    margin: 0px 25px;
    margin-bottom: 25px;
}

.headingFont {
    font-size: 20px;
    color: #0A2043;
}

.subHeadingFont {
    font-size: 18px;
}

.subHeadingValue {
    color: #75B4DF;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .modalContainer {
        /* position: absolute; */
        /* top: 10vw; */
        width: 80%;
        height: 80%;
        min-width: unset;
        min-height: unset;
        /* margin-bottom: 100px; */
        overflow-y: auto;
        /* text-align: center; */
        /* padding-bottom: 20px; */
        border-bottom: 5px white solid;
    }

    .closeModal {
        position: relative;
        margin: 0px !important;
        top: unset;
        top: 10px;
        left: calc(100% - 45px);
        width: 30px;
        height: auto;
    }

    .modalDoctorImg {
        float: unset;
        max-width: unset;
        width: calc(100% - 40px);
        margin: 20px;
        margin-top: 15px;
    }

    .modalContainer>h1, .modalContainer>h2 {
        text-align: center;
        margin: 0px;
        margin-bottom: 10px;
    }

    .modalContainer>p {
        margin-bottom: 20px;
    }
    
}