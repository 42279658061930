@import '../../index.css';

#navHolder{
    width: 100%;
    height: 80px;
    background-color: #fdfdfd;
    display: flex;
    justify-content: center;
}

#navContainer{
    max-width: 1920px;
    display: flex;
    width: 100%;
    background-color: white;
}

#leftNav{
    display: flex;
    width: auto;
    margin-left: 30px;
    align-items: center;
}

#rightNav{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.logoContainer{
    display: flex;
    height: 50px;
    width: auto;
}

#linkContainer{
    justify-content: flex-end;
    height: 100%;
    width: auto;
    line-height: 80px;
    color: #111F43;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    margin-right: 20px;
    border: none;
    justify-content: flex-end;
}


@media screen and (max-width:600px){
    #navHolder{
        height: 60px;
    }

    .logoContainer{
        height: 40px;
    }

    #linkContainer {
        line-height: 60px;
        font-size: 16px;
    }
}

@media screen and (max-width:400px){

    .logoContainer{
        height: 30px;
    }

    #linkContainer {
        font-size: 14px;
    }
}