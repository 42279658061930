.trainingHolder {
    width: 100%;
    display: flex;
    justify-content: center;
}

.backgroundGrey {
    background-color: #E2E2E2;
}

.trainingContainer {
    max-width: 1920px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.brandImageContainer {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brandImage {
    height: 200px;
    width: auto;
}


.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 300px);
    max-width: 700px;
    margin-right: 30px;
}

.brandTitle {
    font-size: 24px;
    color: #112041;
    font-weight: bold;

}

.brandCTA {
    margin-top: 10px;
    font-size: 20px;
    color: #112041;
    overflow-wrap: break-word;
}

.portalButton {
    display: flex;
    cursor: pointer;
    width: 150px;
    background-color: #003E6A;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}


@media screen and (max-width: 700px) {
    .brandImageContainer {
        width: 200px;
    }


    .brandImage {
        height: 120px;
    }

    .trainingContainer{
        height: 250px;
    }

}

@media screen and (max-width: 600px) {

    .trainingContainer {
        height: 200px;
    }

    .rightContainer {
        width: calc(100% - 200px);
        margin-top: 10px;
    }

    .portalButton {
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        width: 120px;
    }

}

@media screen and (max-width:550px) {
    .brandCTA {
        margin-top: 5px;
        font-size: 14px;
    }

    .portalButton {
        margin-top: 10px;
    }

    .brandTitle {
        font-size: 18px;
    }
}

@media screen and (max-width:400px) {
    .brandCTA {
        margin-top: 5px;
    }

    .portalButton {
        margin-top: 10px;
        text-align: center;
    }

    .brandTitle {
        width: 80%;
    }

    .brandTitle {
        font-size: 18px;
        margin: 0px;
    }

    .brandCTA {
        width: 80%;
        font-size: 14px;
        align-items: center;
        margin-right: 0px;
    }

    .trainingContainer {
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

    }

    .rightContainer{
        width: 100%;
       align-items: center;
       margin: 0px;
    }

}