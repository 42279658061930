@import url(https://use.typekit.net/xqj7jov.css);
* {
    margin: 0;
  }
  
  a{
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  body {
    background-color: var(--bg-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

/* @font-face {
  font-family: 'francisco_serial-regular';
  src: url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff2') format('woff2'),
       url('./fonts/FranciscoSerial/francisco_serial-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adamina-Regular';
  src: url('./fonts/Adamina/Adamina-Regular.ttf') format('ttf');
} */

/* .btnText {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.7;
  color: #FFFFFF;
} */

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


#navHolder{
    width: 100%;
    height: 80px;
    background-color: #fdfdfd;
    display: flex;
    justify-content: center;
}

#navContainer{
    max-width: 1920px;
    display: flex;
    width: 100%;
    background-color: white;
}

#leftNav{
    display: flex;
    width: auto;
    margin-left: 30px;
    align-items: center;
}

#rightNav{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.logoContainer{
    display: flex;
    height: 50px;
    width: auto;
}

#linkContainer{
    justify-content: flex-end;
    height: 100%;
    width: auto;
    line-height: 80px;
    color: #111F43;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    margin-right: 20px;
    border: none;
    justify-content: flex-end;
}


@media screen and (max-width:600px){
    #navHolder{
        height: 60px;
    }

    .logoContainer{
        height: 40px;
    }

    #linkContainer {
        line-height: 60px;
        font-size: 16px;
    }
}

@media screen and (max-width:400px){

    .logoContainer{
        height: 30px;
    }

    #linkContainer {
        font-size: 14px;
    }
}
#cardBannerHolder {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
}

#cardBannerContainer {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  background-color: #F3F4F6;
  display: flex;
  align-items: center;
}

.swiper-container {
  height: 100%;
  width: 97%;
}

/* .swiper-pagination{
    top: 272px;
} */

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #111F43;
  opacity: 1;
}

span.swiper-pagination-bullet {
  background-color: #75B4DF;
  opacity: 1;
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
  margin-right: 1px;
}


/* .swiper-wrapper{
    margin-left: 50px;
}
 */



/*////////////*
  /* .sliderWrapper {
    :global(.swiper-pagination-bullet) {
      background: white;
      width: 1rem;
      height: 1rem;
      border-radius: .5rem;
      opacity: 1;
  }
  
  :global(.swiper-pagination-bullet-active) {
    background-color: blue;
    width: 5rem;
    height: 1rem;
    border-radius: .5rem;
  }
} */

@media only screen and (max-width: 700px) {
  #cardBannerHolder {
    height: 400px;
  }
}
#headerHolder{
    width: 100%;
    height: 440px;
    display: flex;
    justify-content: center;
    background-color: #fdfdfd;
}

#headerContainer{
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 1920px;
}

.headerImageStyle {
    width: 100%;
}

.headerContentDiv {
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#headerTitle{
    width: 350px;
    color: white;
    text-align: center;
}

#headerSub{
    width: 360px;
    margin-top: 10px;
    text-align: center;
    color: white;
    font-size: 20px;
}

#headerCTA{
    display: flex;
    cursor: pointer;
    width: 150px;
    background-color: #DB5443;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    color:white;
    margin-top: 20px;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    #headerHolder{
        height: 300px;
    }
}

@media screen and (max-width: 800px) {
    #headerHolder{
        height: 200px;
    }

    #headerTitle{
        font-size: 24px;
    }

    #headerSub{
        font-size: 16px;
    }

    #headerCTA{
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        width: 120px;
    }
}

@media screen and (max-width: 450px) {

    #headerHolder{
        height: 170px;
    }

    #headerTitle{
        font-size: 20px;
        width: 250px;
    }

    #headerSub{
        font-size: 12px;
        margin-top: 5px;
        width: 300px;
    }

    #headerCTA{
        margin-top: 10px;
    }
}
#speakerHolder {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
}

#speakerContainer {
    max-width: 1920px;
    display: flex;
    width: 100%;
    background-color: white;
}

#speakerLeft {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#speakerIcon {
    height: 70px;
    width: auto;
    margin-right: 20px;
}

#speakerText {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 350px;
}

#speakerTitle {
    display: flex;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 5px;
}

#speakerT1 {
    color: #112041;
    margin-right: 5px;
}

#speakerT2 {
    color: #75B4DF;
}

#speakerSub {
    color: #112041;
    font-size: 20px;
}


@media screen and (max-width : 1000px) {

    #speakerLeft {
        width: 100%;
        justify-content: flex-start;
        margin-left: 50px;
    }
}

@media screen and (max-width : 800px) {
    #speakerTitle{
        font-size: 20px;
    }

    #speakerSub{
        font-size: 16px;
    }
}

@media screen and (max-width : 500px){
    #speakerLeft{
        margin-left: 20px;
    }

    #speakerText{
    width: 100%;
    padding-right: 20px;
    }
}
.cardContainer{
    display: flex;
    width: 90%;
    height: 80%;
    margin-top: 20px;
    background-color: #111F43;
    border-radius: 6px 5px 5px 6px;
    overflow: hidden;
}

.speakerImgContainer {
    width: 50%;
    height: 100%;
    /* width: calc(50% - 10px);
    height: calc(100% - 20px); */
    /* margin: 10px; */
    margin-right: 0px;
    background-image: url();
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border-radius: 6px 5px 5px 6px; */
}

/* .speakerImg{
   width: 44%;
   height: auto;
   max-width: auto; 
   border-radius: 5px 0 0 5px;
} */


.cardRight{
    height: 100%;
    width: auto;
    flex-grow: 100;
    color: white;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardText{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    width: 90%;
    margin-top: 2px;
}

.cardButton{
    margin-top: 15px;
    height: 34px;
    width: 60%;
    border-style: solid;
    border-color: white;
    cursor: pointer;
    line-height: 34px;
    text-align: center;
    color: white;
    border-radius: 5px;
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: "";
}

@media only screen and (max-width: 1024px) { 

    .cardText{
        font-size: 20px;
        width: 90%;
    }
    
    .cardButton{
        margin-top: 10px;
        width: 50%;
        border-width: 2px;
    }
}

@media only screen and (max-width: 700px) {
    #cardBannerHolder {
      height: 400px;
    }

    .cardContainer{
        flex-direction: column;
        height: 83%;
    }

    .speakerImgContainer {
        width: 100%;
        height: 60%;
    }

    .cardRight{
        height: 50%;
    }
  }

@media only screen and (max-width: 600px) { 
    .cardText{
        font-size: 20px;
        width: 90%;
    }
    
    .cardButton{
        margin-top: 10px;
        width: 50%;
        border-width: 2px;
    }
}
.modalOverlayContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.modalContainer {
    position: relative;
    background-color: white;
    height: auto;
    width: 50%;
    min-width: 500px;
    min-height: 400px;
}

.closeModal {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0px !important;
    cursor: pointer;
    height: 35px;
}

.modalDoctorImg {
    float: left;
    max-width: 200px;
    margin: 0px 20px 20px 0px;
}

.modalContainer>h1 {
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.modalContainer>h2 {
    margin-bottom: 5px;
}

.modalContainer>p {
    margin: 0px 25px;
    margin-bottom: 25px;
}

.headingFont {
    font-size: 20px;
    color: #0A2043;
}

.subHeadingFont {
    font-size: 18px;
}

.subHeadingValue {
    color: #75B4DF;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .modalContainer {
        /* position: absolute; */
        /* top: 10vw; */
        width: 80%;
        height: 80%;
        min-width: unset;
        min-height: unset;
        /* margin-bottom: 100px; */
        overflow-y: auto;
        /* text-align: center; */
        /* padding-bottom: 20px; */
        border-bottom: 5px white solid;
    }

    .closeModal {
        position: relative;
        margin: 0px !important;
        top: unset;
        top: 10px;
        left: calc(100% - 45px);
        width: 30px;
        height: auto;
    }

    .modalDoctorImg {
        float: unset;
        max-width: unset;
        width: calc(100% - 40px);
        margin: 20px;
        margin-top: 15px;
    }

    .modalContainer>h1, .modalContainer>h2 {
        text-align: center;
        margin: 0px;
        margin-bottom: 10px;
    }

    .modalContainer>p {
        margin-bottom: 20px;
    }
    
}
#footerHolder{
    width: 100%;
    /* height: 100px; */
    display: flex;
    justify-content: center;
}

#footerCenter{
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;
}

#footerLinks{
    height: 60px;
    width: 100%;
    background-color: #111F43;
    max-width: 1920px;
    display: flex;
    align-items: center;
}

#footerBottom{
    height: 100px;
    max-width: 1920px;
    display: flex;
    align-items: center;
    margin-left: 30px;
}

#initialSpace{
    margin-left: 30px;
}

.dot{
    color: white;    
    margin: 0px 15px;
}

#footerIcon{
    display: flex;
    height: 40px;
    width: auto;
}

#crSpace {
    margin-left: 15px;
}

@media screen and (max-width : 1200px ) {
    #footerHolder{
        height: 200px;
    }

    #footerIcon{
        height: 30px;
    }
}

@media screen and (max-width : 700px ) {
    #footerLinks{
        padding-top: 15px;
        flex-direction: column;
        height: 160px;
    }

    #footerBottom{
        justify-content: center;
        margin-left: 0px;
    }

}


.trainingHolder {
    width: 100%;
    display: flex;
    justify-content: center;
}

.backgroundGrey {
    background-color: #E2E2E2;
}

.trainingContainer {
    max-width: 1920px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.brandImageContainer {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brandImage {
    height: 200px;
    width: auto;
}


.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 300px);
    max-width: 700px;
    margin-right: 30px;
}

.brandTitle {
    font-size: 24px;
    color: #112041;
    font-weight: bold;

}

.brandCTA {
    margin-top: 10px;
    font-size: 20px;
    color: #112041;
    overflow-wrap: break-word;
}

.portalButton {
    display: flex;
    cursor: pointer;
    width: 150px;
    background-color: #003E6A;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}


@media screen and (max-width: 700px) {
    .brandImageContainer {
        width: 200px;
    }


    .brandImage {
        height: 120px;
    }

    .trainingContainer{
        height: 250px;
    }

}

@media screen and (max-width: 600px) {

    .trainingContainer {
        height: 200px;
    }

    .rightContainer {
        width: calc(100% - 200px);
        margin-top: 10px;
    }

    .portalButton {
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        width: 120px;
    }

}

@media screen and (max-width:550px) {
    .brandCTA {
        margin-top: 5px;
        font-size: 14px;
    }

    .portalButton {
        margin-top: 10px;
    }

    .brandTitle {
        font-size: 18px;
    }
}

@media screen and (max-width:400px) {
    .brandCTA {
        margin-top: 5px;
    }

    .portalButton {
        margin-top: 10px;
        text-align: center;
    }

    .brandTitle {
        width: 80%;
    }

    .brandTitle {
        font-size: 18px;
        margin: 0px;
    }

    .brandCTA {
        width: 80%;
        font-size: 14px;
        align-items: center;
        margin-right: 0px;
    }

    .trainingContainer {
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

    }

    .rightContainer{
        width: 100%;
       align-items: center;
       margin: 0px;
    }

}
